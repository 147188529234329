import store from "@/store"

/**
 * 上报日志
 * @param { object } params
 * @param { string } params.behaviorType
 * @param { string } params.description
 */
function report(params) {
	if (!store.state.$userInfo.user_id) return

	const imRequestInfo = {
		webMonitorId: 'webfunny_20240808_165354_pro',
		uploadType: 'CUSTOMIZE_BEHAVIOR',
		happenTime: new Date().getTime(),
		behaviorResult: "success",
		userId: store.state.$userInfo.user_id,
		...params
	}

	uni.request({ url: 'https://report2.fxb-team.com/server/upMyLog', method: 'POST', data: { logs: [imRequestInfo] } })
}

// im上报
export async function reportIm(message) {
	report({
		behaviorType: "socket",
		description: `message:${message.content},type:${message.msg_type},receiver:${message.receiver_id},send: ${message.user_id}`
	})
}

// 路径上报
export async function reportPath() {
	report({ behaviorType: "path", description: '' })
}

// 支付上报
export function reportPay(type, description) {
	report({ behaviorType: `pay:${type}`, description: JSON.stringify(description) })
}

// 挂载上报
export function reportMount(page, description) {
	report({ behaviorType: `mount:${page}`, description: JSON.stringify(description) })
}

// 请求上报
export function reportRequest(type, description) {
	uni.getStorage({
		key: 'support_egg',
		success(res) {
			if (new Date().getTime() - res.data < 24 * 60 * 60 * 1000) {
				report({ behaviorType: type, description: JSON.stringify({ url: description.url, data: description.data, params: description.params }) })
			}
		}
	})
}

export function reportError(type, description) {
	report({ behaviorType: `error:${type}`, description: JSON.stringify(description) })
}