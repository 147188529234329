<template><App :keepAliveInclude="keepAliveInclude"/></template><script>
import config from '@/common/config.js'
import { initializeUser } from '@/hooks/useUserInfo.js'
import { useAppInfo } from '@/hooks/useSystemInfo.js'
import '@/util/monitor.min.js'

export default {
	...uni.webfunny({
		globalData: {
			imId: config.imId,
			productId: 0,
			driveProductId: 0,
			volunteerOrderId: 0,
			adParams: {
				aid: '',
				cid: '',
				clickid: '',
				demand_id: ''
			},
		},
		onLaunch(options) {
			console.log('App Launch')
			// 初始化广告
			this.setAdParams(options)
			// 检查版本
			this.checkVersion()
			// 检查能否下单
			this.checkPayable()
			//初始化配置
			this.initConfig()
			// #ifdef MP
			initializeUser()
			// #endif
		},
		onShow() {
			console.log('App Show')
		},
		onHide() {
			console.log('App Hide')
		},
		methods: {
			setAdParams(options) {
				if (options.query?.aid) {
					this.globalData.adParams.aid = options.query.aid;
				}
				if (options.query?.cid) {
					this.globalData.adParams.cid = options.query.cid;
				}
				if (options.query?.clickid) {
					this.globalData.adParams.clickid = options.query.clickid;
				}
				if (options.query?.demand_id) {
					this.globalData.adParams.demand_id = options.query.demand_id;
				}
				if (this.globalData.adParams.clickid) {
					uni.setStorage({ key: 'adParams', data: this.globalData.adParams })
				} else {
					uni.getStorage({
						key: 'adParams',
						success: (adParams) => {
							this.globalData.adParams = adParams
						}
					})
				}
			},
			checkVersion() {
				const updateManager = uni.getUpdateManager()
				updateManager.onUpdateReady(() => {
					uni.showModal({
						title: "更新提示",
						showCancel: false,
						content: "新版本已经准备好，是否重启小程序？",
						success: (res) => {
							if (res.confirm) {
								updateManager.applyUpdate();
							}
						},
					});
				});
				updateManager.onUpdateFailed(() => {
					uni.showToast({ title: "新版本下载失败，请稍后再试", icon: "none" });
				});
			},
			checkPayable() {
				// #ifdef MP-TOUTIAO
				if (!tt.canIUse('microapp-trade-plugin')) {
					uni.showModal({
						title: "当前应用版本较低",
						content: "您的版本较低可能不支持付款，请到应用商店升级“抖音APP”后进行购买",
						showCancel: false
					})
				}
				// #endif
			},
			initConfig() {
				// #ifdef H5
				// 初始化wxJs
				const { isOrange } = useAppInfo()
				if (isOrange) {
					uni.$u.http.get('/v1/home/wx_h5_config', { custom: { href: true } }).then(data => {
						window.wxJS.config(data);
					})
				}
				// #endif
			},
			getPhoneNumber({ params, success, fail }) {
				uni.showLoading({ title: '正在获取', mask: true })
				uni.$u.http.post('/v1/user/auth/get_mobile', params).then(data => {
					success({ phoneNumber: data.mobile })

					uni.hideLoading()
					uni.showToast({ icon: 'none', title: '手机号码获取成功' })
				}).catch(() => {
					fail()

					uni.hideLoading()
					uni.showToast({ icon: 'none', title: '手机号码获取失败，请手动填写手机号码' })
				})
			},
			async beforePayment(product_id) {
				try {
					uni.showLoading({ title: '加载中', mask: true })
					await uni.$u.http.post('/v1/coupon/receive', { product_id }, { timeout: 2000 })
				} catch (error) {
					console.error(error)
				} finally {
					uni.hideLoading()
				}
			}
		}
	})
}
</script>

<style lang="scss">
/*每个页面公共css */
@import "uview-ui/index.scss";
@import "@/style/common.scss";
</style>