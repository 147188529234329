import { useSystemInfo, compareVersion } from '@/hooks/useSystemInfo.js'

const { os, uniPlatform, hostVersion } = useSystemInfo()

/**
 * TODO 优化
 * 能否支付
 */
export function isPayable() {
	if (os !== 'ios') return true
	if (uniPlatform === 'mp-kuaishou') {
		return compareVersion(hostVersion, '11.6.50') < 0
	} else if (uniPlatform === 'mp-toutiao') {
		return true
	} else if (uniPlatform === 'mp-weixin') {
		return false
	} else {
		return true
	}
}

/**
 * 能否继续支付
 */
export function isContinuePayable() {
	if (os !== 'ios') return true
	if (uniPlatform === 'mp-kuaishou') {
		return compareVersion(hostVersion, '11.6.50') < 0
	} else if (uniPlatform === 'mp-toutiao') {
		return false
	} else if (uniPlatform === 'mp-weixin') {
		return false
	} else {
		return true
	}
}

/**
 *	不能支付时，隐藏内容
 * 	@param { boolean } _route 是否跳转首页
 * 	@return { boolean } 是否真正隐藏tabbar和homebutton
 */
export function hideOnUnpayable(_route = false) {
	return false
}