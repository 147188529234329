// CompositionAPI导入的生命周期需要注册，mixin进行全局注册
import config from '@/common/config.js'
import { hideOnUnpayable } from '@/hooks/usePayable'
import { isTrue, isEmpty, isNull } from '@/util/is.js'
import { copy, preview } from '@/util/tool.js'

export default {
	data() {
		return {
			appName: config.appName
		}
	},
	methods: {
		isTrue,
		isEmpty,
		isNull,
		copy,
		preview,
		hideOnUnpayable
	}
}